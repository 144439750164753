import React, { useEffect, useState } from 'react';
import LoginComponent from '../../components/Login/LoginComponent';
import logoMarca from '../../assets/images/orlam-solutions/Imagem6.png'
import './LoginConcursosPage.scss'
import { getFileURL } from '../../databases/storage';
import logConcursos from '../../assets/images/logo-concurso-vertical.png';


const LoginConcursosPage: React.FC = () => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageLogoUrl = logConcursos;

        setLogo(imageLogoUrl);

      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);
  
  return (
    <div className="login-page">
      {logo && (
        <img src={logo} alt="logo matematica do zero" className='signup-logotipo'/>
      )}

      <LoginComponent logoUrl={logo} courseCode="concursos"/>

      <div className="signup-logomarca">
            <a
              href="https://portfolio.or.app.br/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoMarca} alt="logomarca" className="logomarca" />
            </a>
          </div>
    </div>
  );
};

export default LoginConcursosPage;
