import React, { useState } from 'react';
import './LoginComponent.scss';
//import { signUpWithValidate } from '../../utils/SingUp';
import { forgotPassword, signInWithValidate } from '../../utils/SingIn';

import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaUser } from 'react-icons/fa';
import RecaptchaComponent from '../RecaptchaComponent/RecaptchaComponent ';
import AlertModal from '../AlertModal/AlertModal';
import { LoginProps } from '../../interfaces/PropsModels';
import { signOut } from '../../utils/SingOut';


const LoginComponent: React.FC<LoginProps> = (props: LoginProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showButtonForgot, setShowButtonForgot] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [user, setUser] = useState<any>();


  const handleSignUp = async () => {
    // Adicione lógica de validação para os campos, se necessário

    // Chame a função signUpWithValidate
    //await signUpWithValidate(email, password, 'student', true);
  };

  const handleForgotPassword = async () => {
    const response = await forgotPassword(email);
    if(response === 200) {
      setSuccessMessage("Email enviado com sucesso! Caso não tenha recebido verifique a caixa de spam ou entre em contato com nossa equipe");
      setShowModal(true);
    } else {
      setError("Email não existe na nossa base de dados!");
      setShowModal(true);
    }
  };

  const handleSignIn = async (type: string) => {
    try {
      const dataUser = await signInWithValidate(email, password);
      setUser(dataUser);

      // SignIn successful, proceed with redirect
      if (dataUser) {

        if (dataUser?.userRole === 'student') {
          localStorage.setItem('code', dataUser.uid);
          let routeUrl = '';

          if(props.courseCode === 'enem' && dataUser.curso === 'enem') {
            routeUrl = `/app/${dataUser?.userRole}s/${type}`;
          } else if (props.courseCode === 'concursos' && dataUser.curso === 'concursos') {
            routeUrl = `/app/${dataUser?.userRole}s/${dataUser?.curso}/${type}`;
          } else {
            setError("Usuário não está matriculado neste curso! Escolha outro curso ou entre em contato com a secretaria do curso!");
            setShowModal(true);
          }

          if(routeUrl !== '') {
            navigate(routeUrl, {
              state: {
                role: dataUser?.userRole,
                uid: dataUser?.uid,
                email: dataUser?.email,
                logoUrl: props.logoUrl,
                userName: dataUser?.userName,
                curso: dataUser?.curso || 'enem',
              },
            });
          } else {
            if (dataUser?.curso === 'enem') {
              handleLogout('/login');
            } else if (dataUser?.curso === 'concursos') {
              handleLogout('/concursos');
            }
          }

        } else {
          localStorage.setItem('code', dataUser.uid);
          navigate(`/app/adms/${type}`, {
            state: {
              role: dataUser?.userRole,
              uid: dataUser?.uid,
              email: dataUser?.email,
              logoUrl: props.logoUrl,
            },
          });
        }
      } else {
        setError('Usuário não encontrado, entre em contato com a secretaria do curso!');
        setShowModal(true);
      }
    } catch (error: any) {
      setError(
        "Erro ao fazer login. Usuário e senha incorretos ou não existe na base de dados."
      );
      setShowModal(true);
    }
  };

  const handleLogout = async (route: string) => {
    try {
      await signOut();
      navigate(route);
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isEmailValid = (email: string) => {
    // Use a regular expression to check if the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const notIsRobot = (value: string) => {
    if (value) {
      setRecaptcha(true);
    } else {
      setRecaptcha(false);
    }
  }

  const handleSignUpFirstStep = async () => {
    setShowButton(true);
  };

  return (
    <div className="login-component">
      {/* <img src={logo} alt="logo matematica do zero" className='signup-logotipo'/> */}
      <form className="form-signup was-validate">
              <div className="input-group mb-3 mt-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                  title="Insira um email válido!"
                />
                <div className="input-group-append">
                  <span className="input-group-text user-icon">
                    <FaUser />
                  </span>
                </div>
              </div>
              {!showButtonForgot && (
                <div className="input-group mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              )}


              <div className="buttons-container">
                {!recaptcha && (<p style={{fontSize: '10px', textAlign: 'center', color: 'red'}}>Marque não sou um robô para habilitar os botões</p>)}        

                {!showButton ? (
                  <>
                    {!showButtonForgot && (
                      <>
                        <button
                          className="btn btn-primary button-login"
                          type="button"
                          onClick={() => handleSignIn("logged")}
                          disabled={!isEmailValid(email) || password === "" || !recaptcha}
                        >
                          ENTRAR
                        </button>
                        <button
                          className="btn btn-warning button-forgot"
                          type="button"
                          onClick={() => setShowButtonForgot(true)}
                          disabled={!recaptcha}
                        >
                          ESQUECI A SENHA
                        </button>
                        {/* <button
                          className="btn btn-success button-signup"
                          type="button"
                          onClick={handleSignUpFirstStep}
                        >
                          CADASTRE-SE
                        </button> */}
                      </>
                    )}
                    {showButtonForgot && (
                      <>
                        <button
                          className="btn btn-warning button-forgot"
                          type="button"
                          onClick={handleForgotPassword}
                          disabled={!isEmailValid(email) || !recaptcha}
                        >
                          ENVIAR NOVA SENHA
                        </button>

                        <button
                          className="btn btn-success button-signup-cadastro-back"
                          type="button"
                          onClick={() => setShowButtonForgot(false)}
                        >
                          VOLTAR
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-success button-signup-cadastro"
                      type="button"
                      onClick={handleSignUp}
                      disabled={!isEmailValid(email) || password === "" || !recaptcha}
                    >
                      CONCLUIR CADASTRO
                    </button>

                    <button
                      className="btn btn-success button-signup-cadastro-back"
                      type="button"
                      onClick={() => setShowButton(false)}
                    >
                      VOLTAR
                    </button>
                  </>
                )}
              </div>
            </form>

            <RecaptchaComponent getToken={notIsRobot}/>

            {showModal && successMessage && (
              <AlertModal message={successMessage} onClose={handleCloseModal} />
            )}

            {showModal && error && (
              <AlertModal message={error} onClose={handleCloseModal} />
            )}

          
    </div>
  );
};

export default LoginComponent;
