import { SimuladoData } from "../interfaces/Simulados";
import { UserData } from "../interfaces/Students";
import { ClassData } from "../interfaces/Turmas";
import { firestore } from "./firebaseConfig";
import { collection, query, where, getDocs } from 'firebase/firestore';

const addClassesFirestore = async (lesson: ClassData, curso?: string) => {
  try {
    let collection = 'lessons';
    if (curso && curso === "concursos") {
      collection = "lessons-concursos";
    }

    const { id, ...lessonData } = lesson;

    // Cria um novo documento usando o ID fornecido
    const lessonRef = firestore.collection(collection).doc(id);

    // Adiciona os dados do curso ao documento
    await lessonRef.set({
      id,
      ...lessonData,
    });

    console.log("Curso gravado com sucesso!");
  } catch (error) {
    console.error("Erro ao gravar novo curso:", error);
    throw error;
  }
};

const addSimuladoFirestore = async (simulados: SimuladoData) => {
  try {
    const { id, ...simuladosData } = simulados;

    // Cria um novo documento usando o ID fornecido
    const simuladosRef = firestore.collection('simulados').doc(id);

    // Adiciona os dados do curso ao documento
    await simuladosRef.set({
      id,
      ...simuladosData,
    });

    //console.log("Simulado gravado com sucesso!");
  } catch (error) {
    console.error("Erro ao gravar novo simulado:", error);
    throw error;
  }
};


const getClassesFirestore = async (curso?: string): Promise<ClassData[]> => {
  try {
    let collection = 'lessons';
    if (curso && curso === "concursos") {
      collection = "lessons-concursos";
    }
    const snapshot = await firestore.collection(collection).get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ClassData));
  } catch (error) {
    console.error('Erro ao buscar lessons:', error);
    return [];
  }
};


const removeSimuladoFirestore = async (simuladoId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const simuladosRef = firestore.collection("simulados").doc(simuladoId);

    // Remova o documento do Firestore
    await simuladosRef.delete();
  } catch (error) {
    console.error("Erro ao remover aluno no Firestore:", error);
    throw error;
  }
};

const removeClasseFirestore = async (lessonId: string, curso?: string) => {
  try {
    let collection = 'lessons';
    if (curso && curso === "concursos") {
      collection = "lessons-concursos";
    }
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const lessonRef = firestore.collection(collection).doc(lessonId);

    // Remova o documento do Firestore
    await lessonRef.delete();
  } catch (error) {
    console.error("Erro ao remover aula no Firestore:", error);
    throw error;
  }
};

const removeUserFirestore = async (userId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const simuladosRef = firestore.collection("users").doc(userId);

    // Remova o documento do Firestore
    await simuladosRef.delete();
  } catch (error) {
    console.error("Erro ao remover aluno no Firestore:", error);
    throw error;
  }
};


const updateUserFirestore = async (user: UserData) => {
  try {
    const { id, ...userData } = user;
    const courseRef = firestore.collection('users').doc(id);
    await courseRef.update(userData);
    console.log('Usuário atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar usuário no Firestore:', error);
    throw error;
  }
};

const getSimuladosFirestore = async (): Promise<SimuladoData[]> => {
  try {
    const snapshot = await firestore.collection('simulados').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as SimuladoData));
  } catch (error) {
    console.error('Erro ao buscar simulados:', error);
    return [];
  }
};


const getUsersFirestore = async (): Promise<UserData[]> => {
  try {
    const snapshot = await firestore.collection("users").get();

    return snapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() } as UserData))
      // .filter((user) => user.role !== 'student');
  } catch (error) {
    console.error("Erro ao buscar usuários:", error);
    return [];
  }
};


const getClassesFirestoreBySlug = async (value: string, curso: string): Promise<ClassData | any> => {
  try {
    let collectionName = 'lessons';
    if (curso && curso === "concursos") {
      collectionName = "lessons-concursos";
    }

    const collectionRef = collection(firestore, collectionName);

    let q = query(collectionRef, where('slug', '==', value));
    
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() } as ClassData))[0];
  } catch (error) {
    console.error('Erro ao buscar lessons:', error);
    throw error;
  }
};

const updateClasseFirestore = async (lesson: ClassData, curso?: string) => {
  try {
    let collection = 'lessons';
    if (curso && curso === "concursos") {
      collection = "lessons-concursos";
    }
    const { id, ...lessonData } = lesson;
    const lessonRef = firestore.collection(collection).doc(id);
    await lessonRef.update(lessonData);
    console.log('Aula atualizada com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar aula no Firestore:', error);
    throw error;
  }
};


export {
  addClassesFirestore,
  getClassesFirestore,
  getUsersFirestore,
  getClassesFirestoreBySlug,
  addSimuladoFirestore,
  removeSimuladoFirestore,
  getSimuladosFirestore,
  updateUserFirestore,
  removeUserFirestore,
  removeClasseFirestore,
  updateClasseFirestore,
};
