import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import loading from "../../../assets/images/background-default.gif";
import "./CreateClassesConcursos.scss";
import { v4 as uuidv4 } from "uuid"; // Importa a função v4 da biblioteca uuid
import AlertModal from "../../../components/AlertModal/AlertModal";
import { auth } from "../../../databases/firebaseConfig";
import { addClassesFirestore, getClassesFirestore, removeClasseFirestore, updateClasseFirestore } from "../../../databases/firestore";
import { ClassData } from "../../../interfaces/Turmas";
import { signOut } from "../../../utils/SingOut";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";


const CreateClassesConcursos: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const role = state?.role;
  const uid = state?.uid || localStorage.getItem('code');
  const logoUrl = state?.logoUrl;
  const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [videoId, setVideoId] = useState("");
  const [availableAt, setAvailableAt] = useState("");
  const [apostilaUrl, setApostilaUrl] = useState("https://");
  const [challenge, setChallenge] = useState({
    title: "Desafio #1 - Nome da Aula",
    url: "https://",
    folderUrl: "https://"
  });
  const [teacher, setTeacher] = useState({
    name: "Otávio Rafael",
    bio: "Professor e Fullstack Developer",
    avatarUrl: "https://github.com/rafaelxulipa.png",
  });
  const [lessonType, setLessonType] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [lessonsData, setLessonsData] = useState<ClassData[]>([]);
  const [editingLesson, setEditingLesson] = useState<ClassData | null>(null);


  useEffect(() => {
    loadClassesData();
  }, []);

  const loadClassesData = async () => {
    try {
      let classes = await getClassesFirestore('concursos');

      // Ordenando o array pelo campo availableAt
      classes.sort((a, b) => {
        let dateA: any = new Date(a.availableAt);
        let dateB: any = new Date(b.availableAt);
        return dateA - dateB; 
      });

      setLessonsData(classes);
      //localStorage.setItem("classes", JSON.stringify(classes));
      //console.log(classes)
    } catch (error) {
      console.error(
        "Erro ao carregar as aulas cadastradas pelo professor:",
        error
      );
    }
  };

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    if (role === "student") {
      handleLogout();
    }
    // Carrega a imagem de fundo ao montar o componente
    document.body.style.backgroundImage = `url(${imgUrl})`;

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    // Optionally, clear the email and password fields after successful signup
    setTitle("");
    setSlug("");
    setDescription("");
    setVideoId("");
    setAvailableAt("");
    setChallenge({
      title: "Desafio #1 - Nome da Aula",
      url: "https://",
      folderUrl: "https://"
    });
    setTeacher({
      name: "Otávio Rafael",
      bio: "Professor e Fullstack Developer",
      avatarUrl: "https://github.com/rafaelxulipa.png",
    });
    setLessonType("");
    setApostilaUrl("https://");
  };

  const handleCreateClass = async () => {
    const classData: ClassData = {
      id: uuidv4(),
      title,
      slug,
      description,
      videoId,
      availableAt,
      challenge,
      teacher,
      lessonType,
      apostilaUrl,
    };
    try {
      await addClassesFirestore(classData, "concursos");
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro do curso realizado com sucesso!");
      setShowModal(true);

      loadClassesData();
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

function substituirAcentosECedilha(texto: string): string {
    var mapaAcentos: any = {
        'á': 'a',
        'é': 'e',
        'í': 'i',
        'ó': 'o',
        'ú': 'u',
        'â': 'a',
        'ê': 'e',
        'î': 'i',
        'ô': 'o',
        'û': 'u',
        'à': 'a',
        'è': 'e',
        'ì': 'i',
        'ò': 'o',
        'ù': 'u',
        'ã': 'a',
        'õ': 'o',
        'ç': 'c',
        '-' : ' ',
    };

    return texto.replace(/[áéíóúâêîôûàèìòùãõç-]/g, function(matched) {
        return mapaAcentos[matched];
    });
}

const getSlug = (text: string) => {
    // Obter o valor do campo de texto
    var textoOriginal = text;

    // Substituir caracteres acentuados ou cedilhados por suas versões sem acento
    var textoSemAcento = substituirAcentosECedilha(textoOriginal);

    // Remover caracteres indesejados e substituir espaços por hífens
    var textoFormatado = textoSemAcento.toLowerCase().replace(/[^a-z0-9-]/g, " ").replace(/\s+/g, "-");
    
    // Atualizar o valor no campo de texto
    setSlug(textoFormatado);
}

const handleRemoveLesson = async (simuladoId: string) => {
  try {
    await removeClasseFirestore(simuladoId, "concursos");
    setSuccessMessage("Simulado removido com sucesso!");
    setShowModal(true);
    loadClassesData();
  } catch (error: any) {
    setError(error.message);
    setShowModal(true);
  }
};

useEffect(() => {
  if (editingLesson) {
    setTitle(editingLesson.title);
    setSlug(editingLesson.slug);
    setDescription(editingLesson.description);
    setVideoId(editingLesson.videoId);
    setAvailableAt(editingLesson.availableAt);
    setChallenge(editingLesson.challenge);
    setTeacher(editingLesson.teacher);
    setLessonType(editingLesson.lessonType);
    setApostilaUrl(editingLesson.apostilaUrl);
  }
}, [editingLesson]);

const handleUpdateClass = async () => {
  const updatedClassData: ClassData = {
    ...editingLesson!,
    title,
    slug,
    description,
    videoId,
    availableAt,
    challenge,
    teacher,
    lessonType,
    apostilaUrl,
  };

  try {
    await updateClasseFirestore(updatedClassData, "concursos");
    setSuccessMessage("Aula atualizada com sucesso!");
    setShowModal(true);
    loadClassesData(); // Atualiza a lista de aulas após a atualização
    setEditingLesson(null); // Limpa os dados de edição
  } catch (error: any) {
    setError(error.message);
    setShowModal(true);
  }
};



  return (
    <div className="adms-page-create">
      <HeaderComponent urlPhoto={uid} isFirstPage={false} nome={`Bem-vindo ${role}`} logoUrl={logoUrl}/>

      <div className="card-container-users lessons-classes-container">
        <form className="form-signup new-courses was-validated new-lessons">
          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Título da Aula:{" "}
              <p style={{ color: "#969595a0", fontWeight: "normal" }}>
                [Ex.: Aula 1 - Poliedros...]
              </p>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    getSlug(e.target.value);
                }}
                required
                title="Insira o título da aula"
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Slug:
              <input
                type="text"
                className="form-control"
                value={slug}
                onChange={(e) => getSlug(e.target.value)}
                required
              />
            </label>
          </div>
          
          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Descrição:
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                rows={5}
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              ID do youtube:{" "}
              <input
                type="text"
                className="form-control"
                value={videoId}
                onChange={(e) => {
                    setVideoId(e.target.value);
                }}
                required
                title="Insira o título da aula"
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Disponível no dia:
              <input
                type="datetime-local"
                className="form-control"
                value={availableAt}
                onChange={(e) => setAvailableAt(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Título do simulado:
              <input
                type="text"
                className="form-control"
                value={challenge.title}
                onChange={(e) => setChallenge({...challenge, title: e.target.value})}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              URL do simulado:
              <input
                type="text"
                className="form-control"
                value={challenge.url}
                onChange={(e) => setChallenge({...challenge, url: e.target.value})}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              URL da apostila:
              <input
                type="text"
                className="form-control"
                value={apostilaUrl}
                onChange={(e) => setApostilaUrl(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              URL da pasta da aula:
              <input
                type="text"
                className="form-control"
                value={challenge.folderUrl}
                onChange={(e) => setChallenge({...challenge, folderUrl: e.target.value})}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Nome do professor da aula:
              <input
                type="text"
                className="form-control"
                value={teacher.name}
                onChange={(e) => setTeacher({...teacher, name: e.target.value})}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              BIO do professor da aula:
              <input
                type="text"
                className="form-control"
                value={teacher.bio}
                onChange={(e) => setTeacher({...teacher, bio: e.target.value})}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Foto do professor da aula:
              <input
                type="text"
                className="form-control"
                value={teacher.avatarUrl}
                onChange={(e) => setTeacher({...teacher, avatarUrl: e.target.value})}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <select
              value={lessonType}
              onChange={(e) => setLessonType(e.target.value)}
              className="form-control"
              required
            >
              <option value="" hidden disabled>
                Escolha o tipo da aula
              </option>
              <option value="class">GRAVADA</option>
              <option value="live">AO VIVO</option>
            </select>
          </div> 

          <div className="buttons-container">
            {/* <button
              className="btn btn-primary adm-button"
              type="button"
              onClick={handleCreateClass}
            >
              CONCLUIR CADASTRO DA AULA
            </button> */}
            <button
              className="btn btn-primary adm-button"
              type="button"
              onClick={editingLesson ? handleUpdateClass : handleCreateClass}
            >
              {editingLesson ? 'ATUALIZAR AULA' : 'CONCLUIR CADASTRO DA AULA'}
            </button>
          </div>
        </form>

        <div className="create-simulados-list" style={{ color: "#fff" }}>
          {lessonsData.length > 0 && (
            <>
              <h3 style={{ color: "#fff", marginTop: "20px", textAlign: 'center' }}>
                Lista de Cursos Cadastrados
              </h3>
              <ul>
                {lessonsData.map((lesson) => (
                  <li key={lesson.id}>
                    <span>{lesson.title}</span>
                    <div style={{display: 'flex', gap: '1rem'}}>
                    <button
                      type="button"
                      onClick={() => setEditingLesson(lesson)}
                      className="btn btn-info"
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      onClick={() => handleRemoveLesson(lesson.id)}
                      className="btn btn-warning"
                    >
                      Remover
                    </button>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default CreateClassesConcursos;
