import React, { useEffect, useState } from 'react';
import './HomePage.scss'
import { Link } from 'react-router-dom';
import { getFileURL } from '../../databases/storage';
import logConcursos from '../../assets/images/logo-concurso-vertical.png'

const HomePage: React.FC = () => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = await getFileURL("home-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty('--background-image', 'url(' + imageUrl + ')');
        
        const imageUrlMobile = await getFileURL("home-page/background-mobile");
        document.documentElement.style.setProperty('--background-image-mobile', 'url(' + imageUrlMobile + ')');


        const imageLogoUrl = await getFileURL("login-page/logotipo");

        setLogo(imageLogoUrl);

      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);
  
  return (
    <div className='home-page'>
      <h1 className='home-page_title'>Seja Bem-vindo!</h1>
      <div className='home-page_elements-container'>
        <div className='home-page_elements-container_items'>
          {logo && (
            <img src={logo} alt="logo matematica do zero" width={180} height={180} />
          )}
          <h1 className='home-page_elements-container_items-title' style={{textAlign: 'center'}}>MATEMÁTICA DO ZERO</h1>
          <Link to={'/login'} style={{backgroundColor: '#0000E7', padding: '4px 8px', borderRadius: '4px', color: '#fff', textDecoration: 'none'}}> FAZER LOGIN </Link>
        </div>
        <div className='home-page_elements-container_items'>
          {logo && (
            <img src={logConcursos} alt="logo matematica do zero" width={150} height={180}/>
          )}
          <h1 className='home-page_elements-container_items-title' style={{textAlign: 'center'}} >MATEMÁTICA PARA CONCURSOS</h1>
          {/* <p>Seja Bem-vindo!</p> */}
          <Link to={'/concursos'} style={{backgroundColor: '#0000E7', padding: '4px 8px', borderRadius: '4px', color: '#fff', textDecoration: 'none'}}> FAZER LOGIN </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
