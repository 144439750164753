import React, { useEffect, useState } from "react";
import "./HomeStudent.scss";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Video } from "../../../components/VideoComponent/VideoComponent";
import PendulumCard from "../../../components/PendulumCard/PendulumCard";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { getFileURL } from "../../../databases/storage";
import { auth } from "../../../databases/firebaseConfig";
import concursosLogo from "../../../assets/images/logo-concurso-vertical.png"
import concursosHorizontal from "../../../assets/images/logo-concurso-horizontal.png"

const HomePageStudentConcursos: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const { state } = useLocation();
  const uid = state?.uid || localStorage.getItem('code');
  const curso = state?.curso || 'enem';
  const role = state?.role ? state?.role : "student";
  const nome = state?.userName ? state?.userName : "Estudante";
  const partesDoNome = nome.split(" ");
  const logoUrl = state?.logoUrl;
  const [imgUrl, setImgUrl] = useState("");

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Check if the user is already signed in
    
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = await getFileURL("students-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty(
          "--background-image-student",
          "url(" + imageUrl + ")"
        );

        const imageUrlMobile = await getFileURL(
          "students-page/background-mobile"
        );
        document.documentElement.style.setProperty(
          "--background-image-mobile-student",
          "url(" + imageUrlMobile + ")"
        );

        setImgUrl(imageUrl);
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);

  const isFirstPageValue = role === 'adm' ? false : true;

  return (
    <div className="student-page">
      <div className={`student-page_container ${curso}`}>
        <HeaderComponent
          urlPhoto={uid}
          isFirstPage={slug ? false : isFirstPageValue}
          nome={`Bem-vindo ${partesDoNome[0]}`}
          logoUrl={curso === "concursos" ? concursosLogo : logoUrl}
          curso={curso}
        />
        <main className={`student-page_container-main ${curso}`}>
          {slug && (
            <Video
              lessonSlug={slug}
              urlPhoto={uid}
              userName={`Bem-vindo ${partesDoNome[0]}`}
              logoUrl={logoUrl}
              imgUrl={imgUrl}
              curso={curso}
            />
          )}
          {!slug && (
            <div className={`student-page_novideo ${curso}`}>
              <img
                className="img-concursos"
                src={concursosHorizontal}
                alt="Galera dos concursos"
              />
              <p className={`student-page_novideo-description ${curso}`}>
                Preparar-se para concursos públicos é um passo decisivo em busca
                de estabilidade e crescimento profissional. Entre as disciplinas
                essenciais para a maioria dos concursos, a Matemática se destaca
                como um pilar fundamental. Dominar a matemática básica e
                desenvolver um raciocínio lógico sólido são habilidades cruciais
                que não apenas facilitam a resolução de questões específicas,
                mas também auxiliam no entendimento de problemas em diversas
                áreas do conhecimento. Pensando na importância dessa preparação,
                <span>
                  <a
                    href="https://blog.otaviorafael.com.br"
                    className="mx-2"
                    style={{ textDecoration: "none", color: "blue" }}
                    target="_blank"
                  >
                    Blog Fonte do Saber
                  </a>
                </span>
                , em parceria com o Fonte do Saber Cursos, organizou um conteúdo
                abrangente e direcionado de Matemática para concursos públicos.
                Esse material foca nos temas mais cobrados, como aritmética,
                álgebra, geometria e análise combinatória, além de enfatizar a
                importância do raciocínio lógico. Com um estudo bem estruturado
                e direcionado, você estará melhor preparado para enfrentar as
                provas e conquistar a tão almejada aprovação.
              </p>

              <div className="student-page_novideo-sub-description">
                <div className="sub-description_container">
                  <h1 className="title">MATEMÁTICA:</h1>
                  <ul className="list">
                    <li>Operações com números naturais</li>
                    <li>Múltiplos e divisores</li>
                    <li>Números primos</li>
                    <li>Máximo divisor comum e Mínimo múltiplo comum</li>
                    <li>Frações</li>
                    <li>Porcentagem</li>
                    <li>Medidas de comprimento, área, tempo, massa, capacidade e velocidade</li>
                    <li>Áreas das figuras planas</li>
                    <li>Juros simples e compostos</li>
                    <li>Média e noções de estatística</li>
                  </ul>
                </div>

                <div className="sub-description_container">
                  <div className="pendulum">
                    <PendulumCard
                      title="AULAS LIBERADAS"
                      value="30/01/2025" //"NO AR"
                      type="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {!slug && (
            <Sidebar logoUrl={logoUrl} studentName={nome} curso={curso} />
          )}
        </main>
      </div>
    </div>
  );
};

export default HomePageStudentConcursos;
